import React, { FunctionComponent, useEffect, useState } from "react";
import * as Styled from "./FirstLevelSection.styled";
import Notification from "../../../../components/Notification/Notification";
import { AuthContext } from "../../../../context/AuthContext";
import api from "../../../../services/api";
import FileUploader from "./components/FileUploader";
import ProgressBar from "./components/ProgressBar";
import { Application } from "../../../../types/Application";
import { HOST } from "../../../../config";
import { Button } from "carbon-components-react";
import StudentFiles from "../../../../components/StudentFiles/StudentFiles";
type Props = {};

const FirstLevelSection: FunctionComponent<Props> = (props) => {
  const {
    session: { user },
  } = React.useContext(AuthContext);

  const [dateOption, setDateOption] = useState<{ value: string } | undefined>();
  const [grade, setGrade] = useState();
  const [gradesOption, setGradesOption] = useState<
    { value: boolean } | undefined
  >();
  const [isWinner, setIsWinner] = useState<boolean>(false);
  const [application, setApplication] = useState<Application | undefined>(
    undefined
  );

  const getDateOption = () => {
    api.get("/options").then((r) => {
      setDateOption(r.data["hydra:member"][0]);
    });
  };

  const getApplications = () => {
    api.get("/applications").then((r) => {
      setGrade(
        r.data["hydra:member"].length > 0
          ? r.data["hydra:member"][0].gradeFirstStage
          : undefined
      );
      setApplication(r.data["hydra:member"][0]);
    });
  };

  const getGradesOption = () => {
    api.get("/options").then((r) => {
      setGradesOption(
        r.data["hydra:member"].length > 0
          ? r.data["hydra:member"][1]
          : undefined
      );
    });
  };

  let currentDate = new Date();

  const getOptionsDate = (options: any) => {
    return new Date(options.value);
  };

  useEffect(() => {
    getDateOption();
    getGradesOption();
    getApplications();
  }, []);

  useEffect(() => {
    setIsWinner(Number(grade) >= 20);
  }, [grade]);

  return (
    <Styled.FirstLevelSection>
      <Styled.Container>
        <Styled.Title>ETAP I</Styled.Title>
        <Styled.Descending>
          Swoją pracę możesz wgrać do:{" "}
          <p style={{ fontWeight: "bold", display: "inline-block" }}>
            {dateOption
              ? new Date(dateOption.value).toLocaleDateString()
              : "nie podano"}
          </p>
        </Styled.Descending>
        {user?.roles.includes("ROLE_STUDENT_ACCEPTED") ? (
          dateOption ? (
            getOptionsDate(dateOption) > currentDate ? (
              <FileUploader onUploadSuccess={getApplications} />
            ) : gradesOption?.value && grade !== null && grade !== undefined ? (
              <ProgressBar
                value={grade}
                isWinner={isWinner}
                maxValue={"30"}
                title={"Twój wynik pracy etapu I to:"}
                descending={
                  isWinner
                    ? "Gratulujemy! Przechodzisz do następnego etapu olimpiady!"
                    : "Przykro nam, ale nie uzyskałeś wystarczającej liczby punktów, aby przejść do następnego etapu" +
                      " olimpiady. Wymagana liczba punktów to minimum 20 "
                }
              />
            ) : (
              <Notification
                kind={"warning"}
                title={
                  " Niesety, czas na wgrywanie prac minął. Jeżeli wgrałeś swoją pracę, oczekuj maila z informacją o ocenie. Jeżeli dostałeś/aś już maila, a w tym miejscu nie ma twojej oceny, skontaktuj się z komietem."
                }
              />
            )
          ) : (
            ""
          )
        ) : (
          <Notification
            kind={"warning"}
            title={
              "Panel zostanie odblokowany po zaakceptowaniu Twojego zgłoszenia przez Komitet Okręgowy"
            }
          />
        )}
        {application && application.files.length > 0 && (
          <Styled.Subtitle style={{ marginTop: "3rem" }}>
            Poniżej znajdują się pliki, które wgrałeś/aś:
          </Styled.Subtitle>
        )}
        {application && application.files.length > 0 ? (
          <Styled.FilesWrapper>
            <StudentFiles application={application} />
            <Notification
              kind={"warning"}
              title={
                "Każdy nowy wgrany plik pobierz i sprawdź, czy jest właściwy. jeżeli coś się nie zgadza, bądź Twojego pliku nie ma na liście po odświeżeniu strony, niezwłocznie skontaktuj się z Koordynatorem"
              }
            />
          </Styled.FilesWrapper>
        ) : (
          <Styled.Descending style={{ marginTop: "2.3rem" }}>
            Nie wgrałeś/aś żadnych prac
          </Styled.Descending>
        )}
      </Styled.Container>
    </Styled.FirstLevelSection>
  );
};

export default FirstLevelSection;
